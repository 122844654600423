import ConsentBanner from './ConsentBanner'
import React, { type FC,
type PropsWithChildren } from 'react'

const DataProtectionOverlay: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      <ConsentBanner />
    </>
  )
}

export default DataProtectionOverlay
