import './src/styles/global.css'
import DataProtectionOverlay from './src/components/Consent/DataProtectionOverlay'
import DataProtectionProvider from './src/contexts/DataProtectionProvider'
import { ThemeProvider } from '@emotion/react'
import { createTheme } from '@mui/material'
import { type GatsbyBrowser } from 'gatsby'
import React from 'react'

/**
 * The root component for the entire application.
 *
 * It is only used to register providers, theming and other site-wide hooks.
 */
const App: React.FC<React.PropsWithChildren> = ({ children }) => {
  const theme = createTheme({
    components: {
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 20
          }
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: 10,
            boxShadow: '0 10px 10px rgba(0, 0, 0, 0.1)'
          }
        }
      }
    },
    palette: {
      primary: {
        light: '#e0edf8',
        main: '#36446e'
      }
    },
    typography: {
      fontFamily: 'Mabry Pro, sans-serif',
      fontSize: 16
    },
    zIndex: {
      modal: 1_500,
      snackbar: 1_400
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <DataProtectionProvider>{children}</DataProtectionProvider>
    </ThemeProvider>
  )
}

/**
 * Used to set up the providers for the entire application.
 */
export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => {
  return <App>{element}</App>
}

/**
 * Used to consume providers for the entire application.
 */
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => {
  return <DataProtectionOverlay>{element}</DataProtectionOverlay>
}
