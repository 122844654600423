import Checkbox, { type CheckboxProps } from '@mui/material/Checkbox'
import * as React from 'react'

type Props = CheckboxProps & {
  readonly label: string | React.ReactNode
}

export const LabelledCheckbox: React.FC<Props> = ({ label,
...props }) => {
  const [isChecked,
setIsChecked] = React.useState(props.checked)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked
    setIsChecked(newChecked)
    props.onChange?.(event, newChecked)
  }

  return (
    <div className="flex items-center">
      <Checkbox
        {...props}
        checked={isChecked}
        className="mr-2 cursor-pointer"
        name={props.name}
        onChange={handleChange}
        required={props.required}
      />
      <label htmlFor={props.name}>
        {label}
        {props.required && <span className="text-red-500">&nbsp;*</span>}
      </label>
    </div>
  )
}
