import Cookies from 'js-cookie'
import React, {
  createContext,
  type FC,
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState
} from 'react'

// The type of the consent options.
export type ConsentOptions = {
  analytics: boolean
  functional: boolean
  required: boolean
}

// The type of the context value that will be passed through the tree.
type DataProtectionContextType = {
  consent: ConsentOptions
  setConsent: (value: ConsentOptions) => void
  setShowConsentBanner: (value: boolean) => void
  setShowConsentDialog: (value: boolean) => void
  showConsentBanner: boolean
  showConsentDialog: boolean
}

// The default value is used when there is no matching Provider above it in the tree.
export const DataProtectionContext = createContext<DataProtectionContextType>({
  consent: {
    analytics: false,
    functional: false,
    required: false
  },
  setConsent: () => {},
  setShowConsentBanner: () => {},
  setShowConsentDialog: () => {},
  showConsentBanner: false,
  showConsentDialog: false
})

const readCookies = (): ConsentOptions => {
  const userRequiredCookiesConsent = Cookies.get('userRequiredCookiesConsent')
  const userFunctionalCookiesConsent = Cookies.get(
    'userFunctionalCookiesConsent'
  )
  const userAnalyticsCookiesConsent = Cookies.get('userAnalyticsCookiesConsent')

  if (
    userRequiredCookiesConsent &&
    userFunctionalCookiesConsent &&
    userAnalyticsCookiesConsent
  ) {
    return {
      analytics: JSON.parse(userAnalyticsCookiesConsent),
      functional: JSON.parse(userFunctionalCookiesConsent),
      required: JSON.parse(userRequiredCookiesConsent)
    }
  } else {
    return {
      analytics: false,
      functional: false,
      required: false
    }
  }
}

/**
 * The DataProtectionProvider component provides the context for the consent banner and dialog.
 */
const DataProtectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [consent,
setConsent] = useState<ConsentOptions>(() => {return readCookies()})
  const [showConsentDialog,
setShowConsentDialog] = useState<boolean>(false)
  const [showConsentBanner,
setShowConsentBanner] = useState<boolean>(false)

  useEffect(() => {
    Cookies.set(
      'userRequiredCookiesConsent',
      JSON.stringify(consent.required),
      { expires: 365 }
    )
    Cookies.set(
      'userFunctionalCookiesConsent',
      JSON.stringify(consent.functional),
      { expires: 365 }
    )
    Cookies.set(
      'userAnalyticsCookiesConsent',
      JSON.stringify(consent.analytics),
      { expires: 365 }
    )
  }, [consent])

  // Example data protection value
  const dataProtectionValue = useMemo(
    () => {return {
      consent,
      setConsent,
      setShowConsentBanner,
      setShowConsentDialog,
      showConsentBanner,
      showConsentDialog
    }},
    [consent, showConsentBanner, showConsentDialog]
  )

  useEffect(() => {
    if (!consent.required) {
      setShowConsentBanner(true)
    }
  }, [consent.required])

  return (
    <DataProtectionContext.Provider value={dataProtectionValue}>
      {children}
    </DataProtectionContext.Provider>
  )
}

export default DataProtectionProvider
