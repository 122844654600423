exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-case-studies-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/case-studies/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-case-studies-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-low-code-tsx": () => import("./../../../src/pages/low-code.tsx" /* webpackChunkName: "component---src-pages-low-code-tsx" */),
  "component---src-pages-newsroom-index-tsx": () => import("./../../../src/pages/newsroom/index.tsx" /* webpackChunkName: "component---src-pages-newsroom-index-tsx" */),
  "component---src-pages-newsroom-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/newsroom/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-newsroom-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/services/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-services-markdown-remark-frontmatter-slug-tsx" */)
}

